import React from 'react';

const HeroSection = () => {
  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <h1 style={{ fontSize: '4rem', margin: '0' }}>owl: X1 Validator</h1>
      <p style={{ fontSize: '1.5rem', fontWeight: '300', marginTop: '10px' }}>
        Ensuring network reliability and longevity for the future.
      </p>
    </div>
  );
};

export default HeroSection;
