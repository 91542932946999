import React from 'react';
import HeroSection from './components/HeroSection';
import Buttons from './components/Buttons';
import Footer from './components/Footer';
import './background.css';
import './App.css';

const App = () => {
  return (
    <div>
      <HeroSection />
        <div className="landing-page">
        <div className="content">
        <h1>Welcome to Owl Validator - Powering the Future of X1 Network</h1>
        <h2>Join the revolution! Delegate to Owl Validator and support the X1 network.</h2>
      <Buttons />
      <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;

