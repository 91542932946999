import React from 'react';
import owlIcon from '../assets/owl-icon.svg';

const Footer = () => {
  return (
    <footer style={{ marginTop: 'auto', textAlign: 'center', padding: '20px' }}>
      <img src={owlIcon} alt="Owl Icon" style={{ height: '50px', marginBottom: '10px' }} />
      <p>
        Contact us on <a href="https://x.com/youraccount" target="_blank" rel="noopener noreferrer" style={{ color: '#6eafff' }}>X</a>
      </p>
    </footer>
  );
};

export default Footer;
