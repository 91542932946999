import React from 'react';

const Buttons = () => {
  return (
    <div style={{ marginTop: '20px' }}>
      <button
        style={{
          backgroundColor: '#333',
          color: 'white',
          padding: '10px 20px',
          margin: '10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1rem',
        }}
        onClick={() => window.location.href = '/documentation'}
      >
        Documentation
      </button>
      <button
        style={{
          backgroundColor: '#6eafff',
          color: 'white',
          padding: '10px 20px',
          margin: '10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1rem',
        }}
        onClick={() => window.location.href = '/rpc'}
      >
        RPC Page
      </button>
    </div>
  );
};

export default Buttons;
